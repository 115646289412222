<template>
  <h4 class="text-center font-mono text-slate-400 mt-1 px-4 rounded-full py-0.5 bg-slate-900 w-fit mx-auto text-xs lg:text-base">
    <slot />
  </h4>
</template>

<script>
export default {
  name: "ConnectedAddress"
}
</script>

<style scoped>

</style>