<template>
  <div class="w-11/12 mx-auto mt-5 px-5 py-2.5 rounded-lg bg-slate-900/40">
    <h5 class="text-indigo-400 text-lg font-medium text-center">Minting your NFT...</h5>
    <p class="text-center text-sm">
      Depending on how busy the network is, this may take a few minutes. This screen
      will automatically update when your NFT has been minted.
    </p>


    <div class="progress-bar mt-3 bg-slate-700 rounded-full">
      <div class="progress-bar-value bg-indigo-600"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MintInProgress"
}
</script>

<style scoped>


.progress-bar {
  height: 5px;
  width: 100%;
  overflow: hidden;
}

.progress-bar-value {
  width: 100%;
  height: 100%;
  animation: indeterminateAnimation 1.5s infinite linear;
  transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
  0% {
    transform:  translateX(0) scaleX(0);
  }
  40% {
    transform:  translateX(0) scaleX(0.4);
  }
  100% {
    transform:  translateX(100%) scaleX(0.5);
  }
}
</style>