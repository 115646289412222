<template>
  <div class="mt-6 bg-slate-800 p-2 shadow text-slate-400 max-w-md rounded-lg text-center">
    <p class="font-medium text-lg text-slate-300">
      Invalid Site
    </p>
    <p>
      There is no SecuroMint site at <span class="text-slate-300 font-medium">{{domain}}</span>. Please contact
      the site owner to resolve this issue.
    </p>
  </div>
</template>

<script>
export default {
  name: "InvalidSite",
  props: {
    domain: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>

</style>