<template>
  <div class="bg-green-500 mt-4 py-3">

    <CheckCircleIcon class="h-8 mb-2 text-white mx-auto"/>

    <p class="text-center text-white font-medium">
      Congratulations!
    </p>
    <p class="text-center text-sm text-white">
      You've successfully minted a {{ project }}.
    </p>
    <a :href="`https://${testnet ? 'goerli.' : ''}etherscan.io/tx/${receipt.transactionHash}`" target="_blank"
       class="bg-white w-fit px-3 py-1 rounded-lg text-indigo-600 font-medium mx-auto block mt-2 text-xs">
      View Transaction on Etherscan
    </a>
  </div>
</template>

<script>
import {CheckCircleIcon} from "@heroicons/vue/24/solid/index.js";

export default {
  name: "Congratulations",
  components: {
    CheckCircleIcon
  },
  props: {
    testnet: {
      type: Boolean,
      default: false
    },
    receipt: {
      type: Object,
      default: {
        transactionHash: ''
      }
    },
    project: {
      type: String,
      default: 'NFT'
    }
  }
}
</script>

<style scoped>

</style>