<template>
  <div>
    <div class="mb-3 text-xs flex justify-center gap-6 text-white/40">
      <a class="text-center" target="_blank" href="https://www.securomint.com/legal/terms">Terms &
        Conditions</a>
      <a class="text-center" target="_blank" href="https://www.securomint.com/legal/terms">Privacy Policy</a>
    </div>

    <SecuroMint class="h-4 mb-6 text-slate-500 mx-auto"/>
  </div>
</template>

<script>
import SecuroMint from "../../branding/SecuroMint";
export default {
  name: "CardFooter",
  components: {SecuroMint}
}
</script>

<style scoped>

</style>