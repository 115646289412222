<template>
  <p class="text-center mt-4 px-8">
    <slot />
  </p>
</template>

<script>
export default {
  name: "ProjectDescription"
}
</script>

<style scoped>

</style>