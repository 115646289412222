<template>
  <h5 class="text-center mt-5 text-slate-500 uppercase mb-2 font-bold text-xs">

    <span>
      <WifiIcon class="inline h-4 -mt-1"/>
    </span>
    Connected to Web3

    <a href="javascript:void(0)" @click="disconnect">
      <span class="text-slate-400 hover:text-slate-300">Disconnect</span>
    </a>
  </h5>

</template>

<script>
export default {
  name: "IsConnected",
  methods: {
    disconnect() {
      localStorage.removeItem('WEB3_CONNECT_CACHED_PROVIDER')
      window.location.reload();
    },
  }
}
</script>

<style scoped>

</style>