<template>
  <div>
    <IsConnected/>

    <ConnectedAddress>
      {{ web3.currentProvider.selectedAddress }}
    </ConnectedAddress>

    <!-- Status Messages -->
    <Web3Error :error="error"/>
    <Congratulations v-if="success" :receipt="receipt" :project="title" :testnet="testnet"/>

    <!-- Mint Buttons -->
    <MintInProgress v-else-if="mintingInProgress"></MintInProgress>
    <MintButton v-else-if="proofs.length" @activate="doMint"/>
    <ConnectingToSecuroMint v-else-if="!connectError.length"/>
  </div>
</template>

<script>
import IsConnected from "../web3/IsConnected";
import ConnectedAddress from "../web3/ConnectedAddress";
import Web3Error from "../web3/Web3Error";
import Congratulations from "../web3/Congratulations";
import MintInProgress from "../web3/button/MintInProgress";
import MintButton from "../web3/button/MintButton";
import ConnectingToSecuroMint from "../web3/ConnectingToSecuroMint";

export default {
  name: "WalletConnectedScreen",
  components: {
    ConnectingToSecuroMint, MintButton, MintInProgress, Congratulations, Web3Error,
    ConnectedAddress, IsConnected
  },
  data() {
    return {
      error: '',
      success: false,
      mintingInProgress: false,
      receipt: null,
      locked: false
    }
  },
  methods: {
    doMint() {
      if (this.locked) {
        return;
      }

      console.log('minting with cost:', this.cost)

      this.locked = true;
      const contract = new this.web3.eth.Contract(this.abi, this.project.address);
      this.mintingInProgress = true;

      contract.methods.mint(1, this.proofs).send({
        from: this.web3.currentProvider.selectedAddress,
        value: this.cost
      }).then((receipt) => {
        this.mintingInProgress = false;
        this.receipt = receipt;
        this.success = true;
      }).catch((err) => {
        this.mintingInProgress = false;
        this.error = err.message;
      }).finally(() => {
        this.locked = false;
      });
    },
  },
  props: {
    proofs: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    project: {
      type: Object,
      required: true
    },
    testnet: {
      type: Boolean,
      required: true
    },
    web3: {
      type: Object,
      required: true
    },
    connectError: {
      type: String,
      required: false,
      default: ''
    },
    abi: {
      type: Object,
      required: true
    },
    cost: {
      type: Number,
      required: true
    }
  },
}
</script>

<style scoped>

</style>