<template>
  <div
       class="py-3 px-12 bg-indigo-600 text-center text-white text-lg rounded w-fit mx-auto mt-5 font-medium shadow cursor-pointer hover:bg-indigo-700"
       @click="$emit('activate')">
    Mint an NFT
  </div>
</template>

<script>
export default {
  name: "MintButton"
}
</script>

<style scoped>

</style>