<template>
  <Transition>
    <!-- Loading Screen whilst pulling mint page data from the SecuroMint API -->
    <LoadingScreen v-if="ascertaining" :domain="domain" :invalid-domain="invalidDomain"></LoadingScreen>

    <div class="h-[100vh] absolute w-full" v-else>
      <div class="wrap"></div>
      <div class="bg"></div>

      <div class="flex flex-col h-full items-center justify-center gap-2.5 z-10">
        <TestnetWarning v-if="testnet"></TestnetWarning>

        <MainCard>

          <CardHeader :background="project.backgrounds.card">
            <ProjectLogo :src="project.logo"/>
            <ProjectName>
              {{ project.name }}
            </ProjectName>

            <VerifiedCreator/>
          </CardHeader>

          <div class="flex-1">
            <ProjectDescription>
              {{ project.description }}
            </ProjectDescription>

            <hr class="mt-10 border-slate-600">
            <Web3Error :error="error"></Web3Error>

            <div v-if="!canMint">
              <Web3Error error="This project is not currently minting."></Web3Error>
            </div>
            <WalletConnectedScreen
                v-else-if="connected"
                :connect-error="error"
                :testnet="testnet"
                :web3="web3"
                :project="contract"
                :proofs="proofs"
                :abi="abi"
                :cost="mintingPrice"
                :title="project.name"
            />
            <ConnectWallet v-else @connected="connect" :testnet="testnet"/>
          </div>

          <div class="pt-6">
            <EtherscanLink :address="contract.address" :testnet="testnet"/>
            <CardFooter/>
          </div>
        </MainCard>
      </div>
    </div>
  </Transition>
</template>

<script setup>
import TestnetWarning from "../components/ui/card/TestnetWarning";
import ProjectName from "../components/ui/project/ProjectName";
import ProjectLogo from "../components/ui/project/ProjectLogo";
import MainCard from "../components/ui/card/MainCard";
import CardHeader from "../components/ui/card/CardHeader";
import VerifiedCreator from "../components/ui/project/VerifiedCreator";
import ProjectDescription from "../components/ui/project/ProjectDescription";
import Web3Error from "../components/ui/web3/Web3Error";
import CardFooter from "../components/ui/card/CardFooter";
import LoadingScreen from "../components/ui/screen/LoadingScreen";
import WalletConnectedScreen from "../components/ui/screen/WalletConnectedScreen";
import ConnectWallet from "../components/ui/web3/button/ConnectWallet";
import EtherscanLink from "../components/ui/web3/EtherscanLink";

// noinspection JSUnresolvedFunction
definePageMeta({
  layout: 'custom'
})
</script>
<script>
import {CheckBadgeIcon, MagnifyingGlassCircleIcon, WifiIcon} from "@heroicons/vue/24/solid/index.js";
import Web3 from 'web3';
import Web3Modal from 'web3modal';
import WalletConnect from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from '@coinbase/wallet-sdk';
import SecuroMint from "../components/branding/SecuroMint";
import {useApiBase} from "../composables/api";

// noinspection JSUnusedGlobalSymbols
export default {
  name: "MintPage",
  components: {
    CheckBadgeIcon,
    MagnifyingGlassCircleIcon,
    WifiIcon,
    SecuroMint
  },
  head() {
    let title = 'SecuroMint';

    try {
      if (this.data.project.name !== '') {
        title = `Mint ${this.data.project.name} NFTs`;
      }
    } catch (e) {
      // ignore
    }

    return {
      title
    }
  },
  data() {
    return {
      canMint: false,
      ascertaining: true,
      invalidDomain: false,
      loading: true,
      abi: {},
      proofs: [],
      contract: {
        address: ''
      },
      web3: null,
      error: '',
      wallet: {
        connected: false
      },
      mintingInProgress: false,
      mintingPrice: 0,
      project: {
        name: '',
        id: '',
        verified: true,
        description: '',
        fiat: false,
        logo: '',
        backgrounds: {
          page: '',
          card: ''
        }
      },
      receipt: {},
      success: false,
      testnet: false
    }
  },
  methods: {
    async connect(instance) {
      this.web3 = new Web3(instance);
      console.log(this.web3)

      $fetch('/abi/mothz.json').then((response) => {
        this.abi = response.abi;
      })

      $fetch(useApiBase(`projects/${this.project.id}/whitelist/proof/${this.web3.currentProvider.selectedAddress}`), {
        method: 'GET'
      }).then((response) => {
        if (response.status !== 'ok') {
          this.error = 'You are not whitelisted for this project.';
          return;
        }
        this.proofs = response.proof;
      }).catch((e) => {
        this.error = 'You are not whitelisted for this project.';
      })
    },
    async loadData() {
      const domain = window.location.hostname;
      $fetch(useApiBase(`projects/mintpage/${domain}`))
          .then((r) => {
            if (r.success) {
              this.testnet = r.data.meta.testnet;
              this.project.fiat = false;
              this.project.name = r.data.strings.title;
              this.project.id = r.data.identifier;
              this.project.description = r.data.strings.description;
              this.project.logo = r.data.images.logo;
              this.project.backgrounds.card = r.data.images.card_background;
              this.project.backgrounds.page = r.data.images.page_background;
              this.project.verified = true;
              this.contract.address = r.data.minting.contract;
              this.canMint = r.data.minting.is_minting;
              this.mintingPrice = r.data.minting.cost;

              this.ascertaining = false;
            } else {
              this.invalidDomain = true;
            }
          })
          .catch(() => {
            this.invalidDomain = true;
          })
    }
  },
  mounted() {
    this.loadData();
  },
  computed: {
    domain: {
      get() {
        if (typeof window === 'undefined') {
          return '';
        }
        return window.location.hostname;
      }
    },
    connected: {
      get() {
        return this.web3 !== null;
      }
    },
    backgrounds: {
      get() {
        return {
          page: `url(${this.project.backgrounds.page})`,
          card: `url(${this.project.backgrounds.card})`
        }
      }
    }
  }
}
</script>

<style scoped>
.wrap {
  background-color: rgb(30 41 59);
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: -3;
}

.bg {
  position: absolute;
  left: 0;
  right: 0;
  z-index: -2;
  display: block;
  background: linear-gradient(rgb(30 41 59 / 50%), rgb(30 41 59 / 40%)), v-bind('backgrounds.page');
  width: 100vw;
  background-size: cover;
  height: 100vh;

  filter: blur(7px);
}

/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
